footer {
  background: #212121;

  .bg-primary {
    h2,
    p {
      color: $secondary-color;
    }
  }

  #map {
    width: 100%;
    height: 300px;
    border:none;
    z-index: 1;
  }

  .contact-details {
    border-bottom: 1px solid #444;
    padding: 10px 0;

    display: flex;
    flex-direction: row;
    justify-content: left;

    a,
    svg {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    p {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  .opening-hours {
    border-bottom: 1px solid #444;
  }

  #copyrightbox {
    border-top: 1px solid #eee;
    padding: 5px 0;
    text-align: center;

    a,
    p {
      color: #fff;
    }

    p {
      margin-bottom: 0;
    }
  }

  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
  }

  svg {
    width: 15px;
    height: 24px;
    fill: #fff;
  }
}